import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Colors } from 'modules/theme';

import { Alerts, Recommendations, Transactions } from 'assets/media/images';

const FinanceComponent = () => (
  <Container className="pb-5" fluid style={{ backgroundColor: Colors.greyDark }}>
    <Container className="pt-5 width-70">
      <Row className="d-flex justify-content-center align-items-center">
        <h4 className="text-center pb-3">Every penny saved goes a long way!</h4>
      </Row>
      <Row className="d-flex justify-content-center align-items-center">
        <h5 className="text-center">
          Yieldbucks is an app designed to simplify your finances and maximize your benefits. Let us
          figure out which credit card is best for every situation. With Yieldbucks, you’ll never
          miss out on rewards and cashback.
        </h5>
      </Row>
    </Container>
    <Container className="py-5">
      <Row style={{ justifyContent: 'space-around' }}>
        <Col xs={12} sm={12} md={3} lg={3} className="px-2 pb-4">
          <Container fluid className="cardHome">
            <Row className="d-flex justify-content-center align-items-center pb-5 px-5">
              <Image src={Recommendations} width="80" />
            </Row>
            <Row className="justify-content-center px-5">
              <p className="text-center textWhite">
                Get recommendations for the perfect credit card to use for any purchase.
              </p>
            </Row>
          </Container>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} className="px-2 pb-4">
          <Container fluid className="cardHome">
            <Row className="d-flex justify-content-center align-items-center pb-5 px-5">
              <Image src={Transactions} width="80" />
            </Row>
            <Row className="justify-content-center px-5">
              <p className="text-center textWhite">
                Maintain a categorized transaction summary for all your cards.
              </p>
            </Row>
          </Container>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} className="px-2">
          <Container fluid className="cardHome">
            <Row className="d-flex justify-content-center align-items-center pb-5 px-5">
              <Image src={Alerts} width="80" />
            </Row>
            <Row className="justify-content-center px-5">
              <p className="text-center textWhite">
                Receive alerts for all your cards from one convenient source.
              </p>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </Container>
);

export default FinanceComponent;
