export { default as AppStore } from './AppStore.png';
export { default as GooglePlay } from './GooglePlay.png';
export { default as TwoPhones } from './TwoPhones.png';
export { default as Phone1 } from './Phone1.png';
export { default as Phone2 } from './Phone2.png';
export { default as Vector1 } from './Vector1.png';
export { default as Alerts } from './Alerts.png';
export { default as Recommendations } from './Recommendations.png';
export { default as Transactions } from './Transactions.png';
export { default as ImgBack } from './ImgBack.png';
export { default as Ellipse } from './Ellipse.png';
export { default as Logo } from './Logo.png';
export { default as Cashback } from './Cashback.png';
export { default as AllCards } from './AllCards.png';
export { default as FindCards } from './FindCards.png';
export { default as GeoLocation } from './GeoLocation.png';
export { default as LinkCards } from './LinkCards.png';
export { default as One } from './One.png';
export { default as Two } from './Two.png';
export { default as Three } from './Three.png';
export { default as Four } from './Four.png';
export { default as CashbackIphone } from './CashbackIphone.png';
export { default as MyCards } from './MyCards.png';
export { default as SearchCards } from './SearchCards.png';
export { default as User1 } from './User1.png';
export { default as User2 } from './User2.png';
export { default as User3 } from './User3.png';
export { default as QuoteLeft } from './QuoteLeft.png';
export { default as QuoteRight } from './QuoteRight.png';
export { default as Vector } from './Vector.png';
export { default as QRAppStore } from './QRAppStore.png';
export { default as QRGooglePlay } from './QRGooglePlay.png';
export { default as Cards } from './Cards.png';
