import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import { headerHeight } from '../modules/theme';

const Content = styled.div`
  padding-top: ${headerHeight}px;
`;

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Content>
        <Component {...props} />
      </Content>
    )}
  />
);

DefaultRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

export default DefaultRoute;
