import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import Footer from '../../components/Footer';

export default class PrivacyPolicyContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container className="mt-5 pb-5">
          <Row className="mt-5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Container fluid className="mt-5">
                <Row className="mt-5">
                  <h1 color={Colors.blueButton}>Privacy Policy</h1>
                </Row>
                <Row className="mt-5">
                  <h5 className="titleh5">
                    This Privacy Policy (“Privacy Policy”) explains how Yieldbucks. (“Yieldbucks,”
                    “we” or “us”) collect, use, and share personally identifiable information of
                    visitors of our web site (the “Site”) and users of our products and services
                    (the “Services”). Please note that the primary purpose of our Site and the
                    Services to get notified/alerted of any nearby cashback rewards/offers in near
                    real time.
                    <br />
                    <br />
                    For this Privacy Policy, information that you provide can be in one of two
                    categories: information relating to your use of the App or site or the Services,
                    including, without limitation, is referred to as “App/Site Information". Privacy
                    Statement describes our (Yieldbucks) privacy policies & practices for users in
                    united states when we process user’s personal data:
                    <br />
                    <br />
                    1) Personal data for the purpose of registration and account maintenance.
                    <br />
                    2) As necessary to improve, run & manage our (Yieldbucks) business & platform
                    (including Mobile application and desktop).
                    <br />
                    3) As necessary to offer best of the platform benefits, offer product
                    recommendations.
                    <br />
                    4) Location history data of 48 Hrs to offer better recommendation services.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">Disclaimers:</span>
                    <br />
                    Yieldbucks does not offer directly cashback/rewards/offers and is not
                    responsible for rewards/cashback earnings & disputes offered by 3rd parties.
                    Cashback’s Rewards & Offers, or any closed loop products/offers shown in
                    notifications are property of respective owners/banks/cards and Yieldbucks has
                    no control whatsoever or responsible/liable in any way. We only provide
                    recommendations based on the best information available from our sources and
                    partners.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Recommendations from 3rd parties: '}
                    </span>
                    You agree any advice, recommendations, suggestions and content provided is for
                    information and tracking purposes only and does not constitute any legal binding
                    or financial and you agree that Yieldbucks is not liable for any actions or
                    decisions taken by you based on recommendations/Suggestions/Advice and you are
                    responsible for your own financial decisions and actions.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Intellectual Property & Copyrights: '}
                    </span>
                    Yieldbucks is a patent pending product and is protected by applicable IP laws
                    and no materials or services or features offered by Yieldbucks may be Copied or
                    Reproduced, Republished, Distributed or Posted anywhere without our exclusive
                    written consent/permission.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Liability & Warranty: '}</span>
                    Yieldbucks does not offer or promise any cashback, rewards or offers directly
                    and is not liable whatsoever and does not guarantee any warranty of
                    offers/services offered, all the cashback, rewards & offers are owned and
                    sponsored by respective 3rd party owners/business/financial institutions and we
                    only provide information & recommendations, and it is up to user’s best
                    judgement to use or not.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Information Accuracy: '}</span>
                    Yieldbucks does not guarantee accuracy of rewards/cashbacks or offers or any
                    errors, inaccurate information, recommendations or notifications.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Add My Card: '}</span>
                    Adding/Linking a credit card is a feature offered by Yieldbucks, by using this
                    feature and adding your cards of financial institutions, you are giving full
                    permissions to Yieldbucks and its 3rd party service providers/partners Plaid,
                    Inc., and agree to the terms of service and Plaid End User Policy{' '}
                    <a href="https://plaid.com/legal/" target="_blank">
                      https://plaid.com/legal/
                    </a>
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Important: '}</span>
                    <span className="font-italic">
                      We do not store your financial institutions authentication details
                      (username/password) in our platform, we securely pass these details to our 3rd
                      party partners/providers who required us to collect in order to verify
                      identity and to provide access to your financial information on our
                      platform/App.
                    </span>
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold" style={{ fontSize: 21 }}>
                      Information Collected:
                    </span>
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Registration/Signup: '}</span>
                    Creating an account, personal information may include your contact information
                    such as full name, phone number, email, address, profile photo, username &
                    credentials, Demographic information.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Security & Biometric Information: '}
                    </span>
                    Additional laws and Regulations may govern Biometric Information and how
                    platform (Yieldbucks Mobile App & desktop) makes use of biometric information.
                    We use biometric information to identify, verify, authenticate and login
                    securely for security purposes, share biometric information with 3rd party
                    service providers who assist with our platform technology, security teams,
                    programs, IT as required by laws and regulations.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Research & Development, Trainings, Support & Feedback: '}
                    </span>
                    Social Media Content: If given permissions we may collect or receive information
                    from social media providers, social media services , email communications & from
                    local businesses along with your social network profiles.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Device Information: '}</span>
                    Data about devices IP addresses ( Internet Protocol), log information, device
                    types, versions, unique identifiers, error messages, cookie data stored locally,
                    for example security verification features, login features etc...
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Location Data: '}</span>
                    Our Platform (Mobile Application & Desktop) will collect precise location data,
                    device motion data, IP address, Latitudes and Longitude data or all , if you
                    grant permissions to do in your device settings for providing real time
                    recommendations and services. We store location history data for the last 48
                    hours only in order to provide better rewards recommendations and avoid repeated
                    notifications of same rewards. We use our Foursquare to get business details
                    based on users current location , for privacy policy details of foursquare
                    please visit foursquare privacy policy page at
                    <a href="https://foursquare.com/products/privacy-center/" target="_blank">
                      {' https://foursquare.com/products/privacy-center/'}
                    </a>
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Device Camera and Contacts: '}
                    </span>
                    Certain product/app features might require access to your device camera &
                    contacts if you grant permissions in your device settings, allow permissions to
                    access in device settings.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Cookies & 3rd Party Cookies Data: '}
                    </span>
                    Cookies, shared data, web beacons, pixels, location data. The app is not
                    configured to respond to devices browsers "DO NOT TRACK" signals/settings, you
                    may have some control on some cookie information we collect and how we may use
                    it but does not guarantee.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">
                      {'Locally Stored Data on Devices: '}
                    </span>
                    Cookie tracking data stored, websites cookie data, we may store personal
                    information locally, data required for synchronization of mobile app and desktop
                    content/features.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'Affiliates & Partners: '}</span>
                    Through partnerships and co-branding we may offer services/products, marketing
                    offers through our campaign's.
                    <br />
                    <br />
                    By using Yieldbucks, Services you consent to the collection, use and disclosure
                    of your personally identifiable Site Information, as applicable to serve you
                    with better recommendations, in accordance with this Privacy Policy.
                    <br />
                    <br />
                    If you request to receive company or product information, or request information
                    about specific Services, or provide comments about Services and overall App ,
                    you will be asked to provide contact information so that we can complete your
                    request. We use this Information to fulfill your request. We may also use this
                    Information to send you additional information about our Services or features to
                    you.
                    <br />
                    <br />
                    You allow us to use this personally identifiable Information to register you to
                    use the Services, and otherwise provide you with the Services; verify your
                    authority to use the app and access the Services; notify you of updates; improve
                    the content and general administration of the App and the Services; provide you
                    with notices regarding the Services and other services that you have
                    enrolled/purchased or may wish to enroll in the future.
                    <br />
                    <br />
                    Yieldbucks may also automatically collect and analyze Information about your
                    general usage of the App, Services and patterns. We might track your usage
                    patterns to see what features & services of the App, you commonly use, traffic
                    volume, frequency of visits/logins, type and time of transactions, type of
                    browser, browser language, IP address and operating system, and statistical
                    information about how you use the Services & App. We only collect, track and
                    analyze such Information in an aggregate manner that does not personally
                    identify you. This aggregate data may be used to assist us in operating the App
                    and the Services and provided to other third parties to enable them to better
                    understand the operation of the Services, and improve services, but such
                    information will be in aggregate form only and it will not contain personally
                    identifiable data.
                    <br />
                    <br />
                    We may share your personally identifiable Site Information with third parties
                    only in the ways that are described in this privacy policy. These include, but
                    are not limited to, sharing Information with service providers to allow them to
                    fulfill your requests and offer best services and products catered to your
                    preferences.
                    <br />
                    <br />
                    We may also disclose your personally identifiable information:
                    <br />
                    <br />
                    (a) if we are required to do so by law, regulation or other government authority
                    or otherwise in cooperation with an investigation of a governmental authority,
                    <br />
                    <br />
                    (b) to enforce these Terms of Use, or
                    <br />
                    <br />
                    (c) to protect the safety of Users of our App, Site and our Services. In
                    addition, we may transfer your personally identifiable information to a
                    successor entity upon a merger, consolidation or other corporate reorganization.
                    <br />
                    <br />
                    We retain Information and personal data we process on behalf of Users for as
                    long as needed to provide the Services. We will retain and use this personal
                    Information as necessary to comply with our legal obligations, resolve disputes,
                    and enforce our agreements with Users, and/or the period required by laws in
                    applicable jurisdictions.
                    <br />
                    <br />
                    Updating, Correcting and Deleting Your Information: Opt-out.
                    <br />
                    <br />
                    If you believe that Yieldbucks has incorrect Information that was provided as
                    part of the Services, you may use the Services to correct, amend, or delete that
                    information.
                    <br />
                    <br />
                    You may also contact us by e-mailing at contact@yieldbucks.com.
                    <br />
                    <br />
                    From time to time, we may send you emails about
                    services/alerts/recommendations/marketing emails or new or modified Services
                    that we believe might interest you. If you wish to opt-out of receiving these
                    materials, you may follow the unsubscribe procedure provided in the email.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold" style={{ fontSize: 21 }}>
                      Security & Data Protection:
                    </span>
                    <br />
                    <br />
                    The security of your personal information is important to us. Yieldbucks employs
                    procedural and technological measures to protect your personally identifiable
                    information. These measures are reasonably designed to help protect your
                    personally identifiable information from loss, unauthorized access, disclosure,
                    alteration or destruction. We may use
                    <span className="titleh5 font-weight-bold">
                      {
                        ' software, secure socket layer technology (SSL) Encryption (AES 256bit), OTP,2-factor authentication, password protection, firewalls, internal restrictions '
                      }
                    </span>
                    and other security measures to help prevent unauthorized access to your
                    personally identifiable information.
                    <br />
                    <br />
                    However, Yieldbucks cannot guarantee that unauthorized third parties will never
                    be able to defeat these measures or use your personally identifiable information
                    for improper purposes. Therefore, you should be aware that when you voluntarily
                    display or distribute personally identifiable information, that information
                    could be collected and used by others. Yieldbucks is not responsible for the
                    unauthorized use by third parties of information you post or otherwise make
                    available publicly.
                    <br />
                    <br />
                    The Site, app, Services and in particular the card data & store affiliate data,
                    may provide links to third party web sites or resources not associated with us
                    and over which we do not have control (“External Web Sites”). Such links do not
                    constitute an endorsement by Yieldbucks of the External Web Sites, the content
                    displayed therein, or the persons or entities associated therewith.
                    <br />
                    <br />
                    You acknowledge that Yieldbucks is only providing these links as a convenience
                    and cannot be responsible for the content of such External Web Sites.
                    <br />
                    <br />
                    Yieldbucks reserves the right to change this Privacy Policy from time to time.
                    We will post the revised Privacy Policy on this Site located at
                    <span className="titleh5 font-weight-bold">
                      {' www.yieldbucks.com/privacy-policy '}
                    </span>{' '}
                    and will either send a notice to the e-mail address that you specified in your
                    registration and/or post notice of the change so it is visible when users log-on
                    for the first time prior to said changes taking effect so that you are always
                    aware of what personal information we may collect and how we may use this
                    information. We encourage you to review this Privacy Policy regularly for any
                    changes. Your continued use of the App and/or Services will be subject to the
                    then-current Privacy Policy.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold">{'CCPA Privacy Policy: '}</span>
                    As per the California Consumer Privacy Act (“CCPA”), and subject to certain
                    limitations and exceptions, if you are a California resident, you may have the
                    following rights with respect to End User Information we have collected about
                    you that constitutes personal information under the CCPA:
                    <br />
                    <br />
                    <ul>
                      <li>
                        To request access to more details about specific pieces of personal
                        information we may have cullected about you in the last 12 months.
                      </li>
                      <li>To request deletion of your personal information & account.</li>
                      <li>
                        To opt-out of any “promotions & sales offers” of your personal information
                      </li>
                      <li>To not be discriminated against for exercising these rights.</li>
                    </ul>
                    <br />
                    To exercise your access or deletion rights, where applicable, you can submit a
                    request using contact form. You may be required to provide additional
                    information necessary to confirm your identity before we can respond to your
                    request.
                    <br />
                    <br />
                    If we receive your request from an agent or authorized 3rd party, we may ask for
                    evidence that you have provided such agent or 3rd party with a power of attorney
                    or that the agent otherwise has valid written authority to submit requests to
                    exercise rights on your behalf.
                    <br />
                    <br />
                    We will consider all such requests and provide response within a reasonable
                    period of time (and within any time period required by applicable law). Please
                    note, however, that certain information may be exempt from such requests, for
                    example if we need to keep the information to comply with our own legal
                    obligations or to establish, exercise, or defend legal claims.
                    <br />
                    <br />
                    <span className="titleh5 font-weight-bold" style={{ fontSize: 21 }}>
                      Last Updated: 5/03/2022 EST
                    </span>
                  </h5>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
