import { keyMirror } from '../modules/helpers';

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  //
  STARTUP: undefined,
  EXCEPTION: undefined,
  SHOW_ALERT: undefined,
  HIDE_ALERT: undefined,
});

export const features = [
  {
    title: 'Card Alerts',
    description: [
      'Yieldbucks sends you important updates for linked credit cards.',
      'Notifications include budget alerts, purchase notifications, and more.',
      'Link your cards to Yieldbucks and stop the endless alerts from multiple apps.',
    ],
  },
  {
    title: 'Budgeting',
    description: [
      'Yieldbucks creates a budget organized by category for all your cards.',
      'Get the most cashback or points without overspending.',
      'Organize your finances with ease using Yieldbucks.',
    ],
  },
  {
    title: 'Card Recommendations',
    description: [
      'Yieldbucks uses geolocation to determine your shopping location.',
      'It checks linked cards to find the ideal credit card for the situation.',
      'The app sends a notification recommending the card with the greatest benefits.',
      'YieldBucks assists with online shopping through its manual search feature.',
    ],
  },
  {
    title: 'Transaction Summary',
    description: [
      'Yieldbucks creates and maintains a categorized summary of transactions across all your credit cards.',
      'No more hassle of keeping up with transactions from several accounts.',
    ],
  },
];
