import React, { Component } from 'react';

import {
  ImgBack,
  LinkCards,
  Cashback,
  One,
  Two,
  Three,
  Four,
  CashbackIphone,
  MyCards,
  SearchCards,
} from 'assets/media/images';

import { Row, Col, Container, Image } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import DownloadComponent from 'components/DownloadComponent';
import Footer from '../../components/Footer';
import Security from '../../components/Security';

export default class HomeContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <Col xs={12} sm={12} md={5} lg={5}>
              <Container>
                <Row className="mt-5">
                  <h1 style={{ color: Colors.blueButton, cursor: 'pointer' }}>How it Works</h1>
                </Row>
                <Row className="mt-5">
                  <h5>
                    Need to know how to choose the best credit card? Let Yieldbucks find the perfect
                    card for every situation.
                  </h5>
                </Row>
              </Container>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image src={Cashback} width="80%" />
            </Col>
          </Row>
        </Container>

        {/* Section 2*/}
        <Container
          fluid
          className="pt-5"
          style={{
            background: 'linear-gradient(to bottom, rgba(36, 52, 84, 1) 80%, rgba(0, 0, 0, 0))',
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="d-flex justify-content-start align-items-start"
              >
                <Image src={One} width="70%" className="pb-5" />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="d-flex justify-content-center align-items-center"
              >
                <Container>
                  <Row>
                    <h2 style={{ color: Colors.yellowExtra }}>Link Credit Cards</h2>
                  </Row>
                  <Row>
                    <h5 className="text-left">
                      Wanna make your credit cards work smarter for you? Pop 'em into the Yieldbucks
                      app! No need to stress, we don't keep any of your money stuff on our platform
                      (check out more deets here). Once they're all linked up, you can switch on
                      real-time alerts. That means we'll give you tips on which credit card could be
                      your best buddy for any spending sitch.
                    </h5>
                  </Row>
                </Container>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="d-flex justify-content-center align-items-center mt-5 mb-5"
              >
                <Image src={LinkCards} width="60%" className=" mb-5" />
              </Col>
            </Row>
          </Container>
        </Container>

        {/* Section 3*/}
        <Container
          fluid
          className="pt-5"
          style={{
            background: 'linear-gradient(to bottom,rgba(0, 0, 0, 0) 80%, rgba(36, 52, 84, 1)',
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="d-flex justify-content-center align-items-center"
              >
                <Image src={Two} width="100%" className="pb-5" />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="d-flex justify-content-center align-items-center mt-5 mb-5"
              >
                <Image src={CashbackIphone} width="60%" className="mb-5" />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="d-flex justify-content-center align-items-center"
              >
                <Container>
                  <Row>
                    <h2 style={{ color: Colors.yellowExtra }}>Get Recommendations</h2>
                  </Row>
                  <Row>
                    <h5 className="text-left">
                      Yieldbucks is like your personal shopping sidekick! We use your location to
                      send you cool card suggestions. So, when you stroll into a store, we check out
                      all your linked cards and pick the perfect one for you. Then, bam! You get a
                      friendly ping from us, suggesting the card that'll score you the best rewards
                      or cashback. Plus, if you're into online shopping, you can hop on the
                      Yieldbucks app and find sweet deals there too.
                    </h5>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Container>
        {/* Section 4*/}
        <Container
          fluid
          className="pt-5 mb-5"
          style={{
            background: 'linear-gradient(to bottom, rgba(36, 52, 84, 1) 80%, rgba(0, 0, 0, 0))',
          }}
        >
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="d-flex justify-content-center align-items-center"
              >
                <Image src={Three} width="100%" className="pb-5" />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="d-flex justify-content-center align-items-center"
              >
                <Container>
                  <Row>
                    <h2 className="text-left" style={{ color: Colors.yellowExtra }}>
                      All Your Credit Cards in One Convenient Space
                    </h2>
                  </Row>
                  <Row>
                    <h5 className="text-left">
                      Picture this: all your credit card stuff neatly organized in one cozy spot –
                      that's Yieldbucks for you! We'll shoot you friendly reminders about credit
                      card payments, nudge you with budget alerts, and keep you in the loop with
                      other handy updates. Plus, we're all about making budgeting a breeze! We'll
                      sort your transactions into neat categories across all your cards. Say goodbye
                      to app-hopping – with Yieldbucks, tracking your spending is a piece of cake!
                    </h5>
                  </Row>
                </Container>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="d-flex justify-content-center align-items-center mt-5 mb-5"
              >
                <Image src={MyCards} width="60%" className="mb-5" />
              </Col>
            </Row>
          </Container>
        </Container>
        {/* Section 5 */}
        <Container fluid className="mb-5">
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col
                xs={6}
                sm={6}
                md={3}
                lg={3}
                className="d-flex justify-content-center align-items-center"
              >
                <Image src={Four} width="100%" className="pb-5" />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={5}
                lg={5}
                className="d-flex justify-content-center align-items-center mt-5 mb-5"
              >
                <Image src={SearchCards} width="60%" className=" mb-5" />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={4}
                lg={4}
                className="d-flex justify-content-center align-items-center"
              >
                <Container>
                  <Row>
                    <h2 style={{ color: Colors.yellowExtra }}>Discover New Credit Cards</h2>
                  </Row>
                  <Row>
                    <h5 className="text-left">
                      Feeling a bit lost on which credit card suits you best? No worries at all!
                      Yieldbucks has your back. We'll give you sweet recommendations on which cards
                      match your spending style perfectly. Pick one you like, sign up, and start
                      racking up rewards that really rock your world!
                    </h5>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container
          fluid
          className="p-0"
          style={{ background: `url(${ImgBack}) no-repeat center center`, backgroundSize: 'cover' }}
        >
          <DownloadComponent />

          <Security />
        </Container>

        <Footer />
      </>
    );
  }
}
