import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import Footer from '../../components/Footer';

export default class TermsConditionsContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container className="mt-5 pb-5">
          <Row className="mt-5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Container fluid className="mt-5">
                <Row className="mt-5">
                  <h1 className="titleH1" style={{ color: Colors.blueButton, fontSize: '30px' }}>
                    Terms & Conditions
                  </h1>
                </Row>
                <Row className="mt-5">
                  <h5 className="titleh5">
                    Terms of Use 1. Introduction.
                    <br />
                    THIS AGREEMENT BINDS YOU OR THE COMPANY YOU REPRESENT (“YOU,” OR “YOUR”) TO THE
                    TERMS AND CONDITIONS SET FORTH HEREIN IN CONNECTION WITH YOUR USE OF
                    Yieldbucks’S (“OUR”, “WE”, “COMPANY” OR “Yieldbucks”) SOFTWARE,SERVICES OR ANY
                    OTHER OFFERINGS ON OUR SITE (COLLECTIVELY TERMED AS OUR
                    “PRODUCTS/FEATURES/SERVICES/CONTENT/VIDEOS/IMAGES”). BY USING ANY OF THE COMPANY
                    PRODUCTS OR CLICKING ON THE “SIGNUP” BUTTON, YOU AGREE TO BECOME BOUND BY THE
                    TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL THE TERMS AND
                    CONDITIONS OF THIS AGREEMENT, CLICK ON THE “CANCEL” BUTTON AND DO NOT USE THE
                    COMPANY PRODUCTS. COMPANY’S ACCEPTANCE IS EXPRESSLY CONDITIONED UPON YOUR ASSENT
                    TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT, TO THE EXCLUSION OF ALL OTHER
                    TERMS. IF THESE TERMS AND CONDITIONS ARE CONSIDERED AN OFFER BY COMPANY,
                    ACCEPTANCE IS EXPRESSLY LIMITED TO THESE TERMS.
                    <br />
                    <br />
                    2. Additional Terms.
                    <br />
                    Any personal information submitted in connection with Your use of the Products
                    or the Site is subject to Our Privacy Policy, located at
                    www.Yieldbucks.com/privacy-policy , which is hereby incorporated by reference
                    into these Terms.
                    <br />
                    <br />
                    3. General.
                    <br />
                    The Product enable users to receive near real time notifications/recommendations
                    about the rewards/offers/discounts about the credit cards owned by users so that
                    users can never miss a reward offered and also pay using appropriate card based
                    on recommendations.
                    <br />
                    <br />
                    Company reserves the right to revise these Terms in its sole discretion at any
                    time by posting the changes on the Site. Changes become effective thirty (30)
                    days after posting. Your continued use of Products/product after change become
                    effective shall mean that You accept those changes. You should visit the Site
                    regularly to ensure You are aware of the latest version of the Terms.
                    Notwithstanding the preceding sentences of this section, no revisions to these
                    Terms will apply to any dispute between you and the Company that arose prior to
                    the date of such revision.
                    <br />
                    <br />
                    The Company may modify the Products or discontinue their availability at any
                    time.
                    <br />
                    <br />
                    You are solely responsible for all service, telephony, data charges and/or other
                    fees and costs associated with Your access to and use of the Products, as Well
                    as for obtaining and maintaining all telephone, computer hardware, and other
                    equipment required for such access and use.
                    <br />
                    If You elect to access or use Products that involve payment of a fee, then You
                    agree to pay, and will be responsible for payment of, that fee and all taxes
                    associated with such access or use. If You provide credit card information to
                    pay for such fees then You hereby represent and warrant that You are authorized
                    to supply such information and hereby authorize the Company to charge Your
                    credit card on a regular basis to pay the fees as they are due.
                    <br />
                    If Your payment method fails or Your account is past due, then We may collect
                    fees owed using other collection mechanisms. This may include charging other
                    payment methods on file with us and/or retaining collection agencies and legal
                    counsel. We may also block Your access to any Products pending resolution of any
                    amounts due by You to Company.
                    <br />
                    All of Your use, access and other activities relating to the Site and the
                    Products must be in compliance with all applicable laws and regulations,
                    including, without limitations, laws relating to copyright and other
                    intellectual property use, and to privacy and personal identity. In connection
                    with Your use of the Products and Site, You must not provide incorrect or
                    knowingly false information; copy, distribute, modify, reverse engineer, deface,
                    tarnish, mutilate, hack, or interfere with the Products or operation of the
                    Site; frame or embed the Site or Products; impersonate another person or gain
                    unauthorized access to another person’s Account; introduce any virus, worm,
                    spyware or any other computer code, file or program that may or is intended to
                    damage or hijack the operation of any hardware, software or telecommunications
                    equipment, or any other aspect of the Products or operation of the Site; scrape,
                    spider, use a robot or other automated means of any kind to access the Products.
                    <br />
                    <br />
                    4. General Disclaimer.
                    <br />
                    <br />
                    You need to take appropriate steps to determine whether accessing a Third Party
                    Site is appropriate, and to protect Your personal information and privacy on
                    such Third Party Site.
                    <br />
                    <br />
                    5. Conduct.
                    <br />
                    You may only access the Products for lawful purposes. You are solely responsible
                    for the knowledge of and adherence to any and all laws, rules, and regulations
                    pertaining to Your use of the Products.
                    <br />
                    <br />
                    You will not post any inappropriate, offensive, racist, hateful, sexist,
                    pornographic, false, misleading, infringing, defamatory or libelous content;
                    <br />
                    You will not upload, post or otherwise transmit any unsolicited or unauthorized
                    advertising, promotional materials, junk mail, spam, chain letters, pyramid
                    schemes or any other form of solicitation (commercial or otherwise) through the
                    Products or to any User;
                    <br />
                    You will maintain Your enrollment/registered and account information, and all
                    such enrollment and account information shall be accurate;
                    <br />
                    You are over the age of 18 or if not a third party parent or legal guardian has
                    agreed to these Terms and will assume responsibility and liability for Your
                    performance and compliance here under.
                    <br />
                    <br />
                    8. Registration.
                    <br />
                    To use certain Products, You will need to register and obtain an account,
                    username and password. When You register, the information You provide to us
                    during the registration process will help us in offering content, customer
                    service, and network management. You are solely responsible for maintaining the
                    confidentiality of Your account, username, and password (collectively, Your
                    “Account”) and for all activities associated with or occurring under Your
                    Account. You represent and warrant that Your Account information will be
                    accurate at all times. You must notify us (a) immediately of any unauthorized
                    use of Your Account and any other breach of security, and (b) ensure that You
                    exit from Your Account at the end of each use of the Products. To the extent
                    permissible under applicable law, we cannot and will not be responsible for any
                    loss or damage arising from Your failure to comply with the foregoing
                    requirements or as a result of use of Your Account, either with or without Your
                    knowledge, prior to Your notifying us of unauthorized access to Your Account.
                    <br />
                    <br />
                    You may not transfer Your Account to any other person and You may not use anyone
                    else’s Account at any time without the permission of the account holder. In
                    cases where You have authorized or registered another individual, including a
                    minor, to use Your Account, You are fully responsible for (i) the online conduct
                    of such User; (ii) controlling the User’s access to and use of the Products; and
                    (iii) the consequences of any misuse.
                    <br />
                    <br />
                    9. Content, Licenses & Permissions.
                    <br />
                    <br />
                    You hereby grant the Company a non-exclusive right and license to reproduce,
                    distribute, publicly perform, offer, market and otherwise use and exploit the
                    Submitted Content on the Site and through the Products, and sub-license and
                    Users for these purposes directly or through third parties. Notwithstanding the
                    foregoing, You have the right to remove all or any portion of Your Submitted
                    Content from the Site at any time. Removal of Your Submitted Content will
                    terminate the foregoing license and rights sixty (60) days after such removal as
                    to new uses, provided, however, that any rights given to Users prior to that
                    time will continue in accordance with the terms granted to such Users.
                    <br />
                    <br />
                    THE COMPANY RESPECTS ALL COPYRIGHT, PRIVACY, DEFAMATION AND OTHER LAWS RELATING
                    TO CONTENT AND INFORMATION AND WILL NOT TOLERATE VIOLATION OF SUCH LAWS.
                    NOTWITHSTANDING THE FOREGOING, THE COMPANY DOES NOT SCREEN THE SUBMITTED CONTENT
                    AND ALL USE OF THE SUBMITTED CONTENT BY YOU IS AT YOUR OWN RISK AND THE COMPANY
                    SHALL HAVE NO LIABILITY FOR SUCH USE. IN PARTICULAR, NO REVIEW OR POSTING OR
                    APPEARANCE OF THE SUBMITTED CONTENT ON THE SITE OR THROUGH THE PRODUCTS IS
                    INTENDED TO ACT AS AN ENDORSEMENT OR REPRESENTATION THAT ANY SUBMITTED CONTENT
                    IS FREE OF VIOLATION OF ANY COPYRIGHT, PRIVACY OR OTHER LAWS OR WILL SUIT A
                    PARTICULAR PURPOSE OR BE ACCURATE OR USEFUL. If You believe that Submitted
                    Content of Yours violates any law or regulation or is inaccurate or poses any
                    risk whatsoever to a third party it is Your responsibility to take such steps
                    You deem necessary to correct the situation. If You believe that Submitted
                    Content of a third party or any Company Content violates any laws or
                    regulations, including, without limitation, any copyright laws, You should
                    report it to the Company with in 14 days and must be removed/deleted from site
                    otherwise site admin have full rights to delete the content in question.
                    <br />
                    <br />
                    10. Trademarks.
                    <br />
                    The trademarks, service marks, and logos (the “Trademarks”) used and displayed
                    on the Site, in the Products or in any Company Content are Our registered or
                    unregistered Trademarks or of Our suppliers or third parties and are protected
                    pursuant to U.S. and foreign trademark laws. All rights are reserved and You may
                    not alter or obscure the Trademarks, or link to them without Our prior approval.
                    <br />
                    <br />
                    11. Warranty Disclaimer.
                    <br />
                    THE PRODUCTS, SITE, COMPANY CONTENT, SUBMITTED CONTENT, COURSES, AND ANY OTHER
                    MATERIALS MADE AVAILABLE ON OR THROUGH THE SITE OR THE PRODUCTS ARE PROVIDED “AS
                    IS,” WITHOUT ANY WARRANTIES OF ANY KIND AND, TO THE FULLEST EXTENT PERMISSIBLE
                    UNDER APPLICABLE LAW, THE COMPANY HEREBY DISCLAIMS ALL SUCH WARRANTIES, EXPRESS
                    OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, ACCURACY, FREEDOM FROM
                    ERRORS, SUITABILITY OF CONTENT, OR AVAILABILITY.
                    <br />
                    <br />
                    12. Limitation of Liability.
                    <br />
                    TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, NEITHER PARTY SHALL BE
                    LIABLE HEREUNDER UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION,
                    CONTRACT, TORT OR NEGLIGENCE, FOR ANY LOSSES, UNLESS SUCH LOSSES WERE REASONABLY
                    FORESEEABLE AT THE TIME YOU AGREED TO THIS TERMS. THE COMPANY’S TOTAL LIABILITY
                    HEREUNDER SHALL BE LIMITED TO THE AMOUNTS PAID IN CONNECTION WITH THE COURSES OR
                    PRODUCTS UNDER WHICH SUCH LIABILITY AROSE. THIS SECTION DOES NOT EXCLUDE OR
                    LIMIT EITHER PARTY’S LIABILITY FOR FRAUD, FOR DEATH, OR FOR PERSONAL INJURY
                    RESULTING FROM NEGLIGENCE, OR FROM ANY OTHER LIABILITY THAT CANNOT BE LIMITED OR
                    EXCLUDED BY LAW. Yieldbucks cannot be hold/held liable for any data or any
                    infringement rights and those content described before are solely belongs to the
                    user/publisher and must be contacted and reached out to users directly.
                    <br />
                    <br />
                    13. Indemnification.
                    <br />
                    You hereby indemnify, defend and hold harmless the Company, and its affiliates,
                    officers, directors, agents, partners, employees, licensors, representatives and
                    third party providers from and against all reasonably foreseeable losses,
                    expenses, damages, costs, claims and demands, including reasonable attorneys’
                    fees and related costs and expenses, due to or arising out of Your breach of any
                    representation or warranty here under. We reserve the right, at Our own expense,
                    to assume the exclusive defense and control of any matter otherwise subject to
                    indemnification by You under this Section 13, and in such case, You agree to
                    fully cooperate as reasonably required with such defense and in asserting any
                    available defenses.
                    <br />
                    <br />
                    14. Termination.
                    <br />
                    We may terminate Your use of the Products or Site immediately without notice for
                    any breach by You of these Terms or any of Our applicable policies, as posted on
                    the Site from time to time.You may terminate Your use of the Site or the
                    Products at any time, either by ceasing to access them, or by contacting us at
                    support@Yieldbucks.com We have no obligation to retain any of Your Account or
                    Submitted Content/data for any period of time beyond what may be required by
                    applicable law. Upon termination, You must cease all use of the Site, Products
                    and Content.
                    <br />
                    <br />
                    15. Electronic Notices.
                    <br />
                    By using the Products or communicating with Company, You agree that Company may
                    communicate with You electronically regarding security, privacy, and
                    administrative issues relating to Your use of the Products or these Terms. If
                    Company learns of a security system’s breach, Company may attempt to notify You
                    electronically by posting a notice on the Products or sending an email to You.
                    You may have a legal right to receive this notice in writing. To receive free
                    written notice of a security breach (or to withdraw your consent from receiving
                    electronic notice), please write to Company at support@Yieldbucks.com.
                    <br />
                    <br />
                    16. Miscellaneous.
                    <br />
                    Entire Agreement. These Terms and any policies applicable to You posted on the
                    Site constitute the entire agreement between the parties with respect to the
                    subject matter hereof, and supersede all previous written or oral agreements
                    between the parties with respect to such subject matter.
                    <br />
                    Severability. If any provision of these Terms is found to be illegal, void or
                    unenforceable, then that provision shall be deemed severable from these Terms
                    and shall not affect the validity and enforceability of any remaining provisions
                    of these Terms.
                    <br />
                    <br />
                    Waiver. A provision of these Terms may be waived only by a written instrument
                    executed by the party entitled to the benefit of such provision. The failure of
                    Company to exercise or enforce any right or provision of these Terms will not
                    constitute a waiver of such right or provision.
                    <br />
                    Notice. Any notice or other communication to be given here under will be in
                    writing and given by facsimile, postpaid registered or certified mail return
                    receipt requested, or electronic mail.
                    <br />
                    <br />
                    No Agency. Nothing in these Terms shall be construed as making either party the
                    partner, joint venture, agent, legal representative, employer, contractor or
                    employee of the other. Neither the Company nor any other party to this Agreement
                    shall have, or hold itself out to any third party as having, any authority to
                    make any statements, representations or commitments of any kind, or to take any
                    action that shall be binding on the other except as provided for herein or
                    authorized in writing by the party to be bound.
                  </h5>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
