import React, { Component } from 'react';
import { Row, Container, Button } from 'react-bootstrap';
import { replace } from 'modules/history';

export default class DownNewsletterContainer extends Component {
  state = { resubscribed: false };
  componentDidMount() {
    this.updateNewsletterStatus(false);
    window.scrollTo(0, 0);
  }

  updateNewsletterStatus = allowNewsletterNotifications => {
    const {
      location: {
        query: { email },
      },
    } = this.props;

    fetch('https://api.yieldbucks.com/api/v1/users/unsubscribe-newsletter', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ allowNewsletterNotifications, email }),
    })
      .then(res => {
        console.log(res);
        if (allowNewsletterNotifications) {
          this.setState({ resubscribed: true });
        }
      })
      .catch(error => {
        console.log('Error in newsletter unsubscribe', error);
      });
  };

  render() {
    const { resubscribed } = this.state;
    return (
      <Container className="mt-5 pt-5 mb-5 pb-5">
        <Row className="justify-content-center mt-5">
          <h2>
            You have successfully {resubscribed ? 'subscribed' : 'unsubscribed'} from the newsletter
          </h2>
        </Row>
        {!resubscribed && (
          <Row className="justify-content-center mt-5">
            <Button
              className="navButton"
              variant="primary"
              onClick={() => this.updateNewsletterStatus(true)}
            >
              <p className="navButtonText"> Subscribe Again </p>
            </Button>
          </Row>
        )}

        <Row className="justify-content-center mt-5">
          <h3 className="navItem pointer" onClick={() => replace('/')}>
            Go to Home
          </h3>
        </Row>
      </Container>
    );
  }
}
