export const headerHeight = 80;

export const appColor = '#4886FF';

export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';

export default {
  breakpoints: {
    xs: 0,
    ix: 400,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920,
  },
  palette: {
    primary: appColor,
    success: '#28a745',
    warning: '#dc3545',
    danger: '#dc3545',
    info: appColor,
    dark: '#343a40',
  },
  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
};
export const Colors = {
  primary: appColor,
  success: '#28a745',
  warning: '#dc3545',
  danger: '#dc3545',
  info: appColor,
  dark: '#343a40',
  transparent: 'rgba(0,0,0,0)',
  white: '#fff',
  // Primary Colors
  whiteTextSecondary: '#999BAF',
  greyMedium: '#60606D',
  greyDark: '#2B2930',
  blackBackground: '#1F2027',
  // Secondary Colors
  blueButton: '#2C6CE8',
  blueLightButtonSecondary: '#C8E6FF',
  yellowExtra: '#FFC42E',
  // Sistem colors
  redErrorUnlinked: '#FF9999',
  greenLinked: '#23B93D',
  blueInformation: '#3591E6',
  yellowWarning: '#FDA624',
  red: '#FF0000',
  black: '#000000',
  blueDark: '#122B5D',
};
