import React, { Component } from 'react';
import './styles.css';
import { Row, Button, Nav } from 'react-bootstrap';
import { push } from 'modules/history';

export default class MenuItemMobile extends Component {
  render() {
    return (
      <Nav
        className="d-block d-sm-block d-md-none d-lg-none mr-auto appBackground"
        style={{ minHeight: '100vh' }}
      >
        <Nav.Link href="#">
          <Row className="justify-content-center mt-2">
            <button
              type="button"
              className="navItem textYellowExtra"
              onClick={() => push('/how-it-works')}
              style={{ background: 'none', border: 'none', padding: 0 }}
            >
              <h3>How it Works</h3>
            </button>
          </Row>
        </Nav.Link>
        <Nav.Link href="#">
          <Row className="justify-content-center mt-5">
            <button
              type="button"
              className="navItem textYellowExtra"
              onClick={() => push('/contact-us')}
              style={{ background: 'none', border: 'none', padding: 0 }}
            >
              <h3>Contact us</h3>
            </button>
          </Row>
        </Nav.Link>

        <Nav.Link href="#">
          <Row className="justify-content-center mt-5">
            <Button className="navButton">
              <p className="navButtonText">Download</p>
            </Button>
          </Row>
        </Nav.Link>
      </Nav>
    );
  }
}
