/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Yieldbucks',
  description: 'Yieldbucks',
  BASE_URL: 'http://localhost:3000/api/v1',
  BASE_URL_WEB: 'http://localhost:3000',
  DONATE_LINK: 'https://www.paypal.com/donate/?hosted_button_id=QSY2RNQ6W9KCU',
};

export default config;
