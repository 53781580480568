import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Icon from '../components/Icon';

const Content = styled.div`
  padding-top: 20vh;
`;

const NotFound = () => (
  <Content>
    <Container className="pb-5">
      <Row className="mt-5">
        <Col xs={12} sm={12} md={12} lg={12} className="text-center">
          <Icon name="fas fa-times-circle" fontSize={200} color={Colors.blueLightButtonSecondary} />
          <h1 style={{ color: Colors.blueLightButtonSecondary }}>404</h1>

          <Link to="/">
            <h2 style={{ color: Colors.blueButton }}>go home</h2>
          </Link>
        </Col>
      </Row>
    </Container>
  </Content>
);

export default NotFound;
