import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import Footer from '../../components/Footer';

export default class ContactUsContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container style={{ minHeight: 'calc(100vh - 221px)' }} className="mt-5 pb-5">
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Container fluid className="mt-5">
                <Row>
                  <h1 className="titleH1" style={{ color: Colors.blueButton, fontSize: '30px' }}>
                    Contact us
                  </h1>
                </Row>
                <Row className="mt-5">
                  <h5>Do you have any questions or suggestions? Contact with us!</h5>
                </Row>
                <Row className="mt-3">
                  <a href="mailto:contact@yieldbucks.com">
                    <i
                      className="fas fa-envelope mr-3"
                      style={{ fontSize: '18px', color: Colors.yellowExtra }}
                    />
                    <span
                      className="textWhite"
                      style={{
                        fontWeight: 'bold',
                        fontStyle: 'normal',
                        fontSize: 18,
                      }}
                    >
                      {' '}
                      contact@yieldbucks.com
                    </span>
                  </a>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
