import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Logo from 'assets/media/images/Logo.png';
import { Colors } from 'modules/theme';
import Icon from './Icon';
import config from '../config';
import { push } from '../modules/history';

const MainWrapper = styled.footer`
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 -2px 10px rgba(72, 134, 255, 0.15);
  background-color: ${Colors.blackBackground};
`;

const Footer = () => (
  <MainWrapper>
    <Container>
      <Row className="pt-5 flex-column flex-md-row">
        <Col className="p-0 pointer d-flex justify-content-center align-items-center">
          <Image src={Logo} width={120} onClick={() => push('/')} />
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => push('/how-it-works')}
          >
            How it Works
          </button>
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => push('/contact-us')}
          >
            Contact us
          </button>
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => push('/privacy-policy')}
          >
            Privacy Policy
          </button>
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => push('/terms-conditions')}
          >
            Terms and conditions
          </button>
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => push('/user-agreement')}
          >
            User Agreement
          </button>
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => push('/faq')}
          >
            FAQ’s
          </button>
        </Col>
        <Col className="d-flex flex-column flex-md-row justify-content-center align-items-center pt-3 px-0 text-center text-md-left">
          <button
            type="button"
            className="navItem pointer text-footer mb-3 mb-md-0"
            onClick={() => window.open(config.DONATE_LINK, '_blank')}
          >
            <Icon
              name="fab fa-paypal"
              fontSize={18}
              customStyle={{ margin: 6, color: Colors.blueButton }}
            />
            Donate
          </button>
        </Col>
      </Row>
    </Container>
  </MainWrapper>
);

export default Footer;
