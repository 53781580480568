import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled, { ThemeProvider } from 'styled-components';

import history from './modules/history';
import theme, { Colors } from './modules/theme';

import config from './config';
import { startup } from './actions';

import Home from './routes/Home';
import HowItWorksContainer from './routes/HowItWorks';
import ContactUsContainer from './routes/ContactUs';
import PrivacyPolicyContainer from './routes/PrivacyPolicy';
import TermsConditionsContainer from './routes/TermsConditions';
import UserAgreementContainer from './routes/UserAgreement';
import FaqContainer from './routes/Faq';
import DownNewsletter from './routes/DownNewsletter';

import Header from './components/Header';

import NotFound from './routes/NotFound';
import GlobalStyles from './components/GlobalStyles';
import DefaultRoute from './components/DefaultRoute';

import 'bootstrap/dist/css/bootstrap.min.css';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
  background-color: ${Colors.blackBackground};
`;

const Main = styled.main``;

export class App extends React.Component {
  constructor(props) {
    super(props);

    const { startupApp } = this.props;
    startupApp();
  }

  static propTypes = {
    startupApp: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AppWrapper>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: 'pt-br' }}
              encodeSpecialCharacters={true}
              defaultTitle={config.name}
              titleTemplate={`%s | ${config.name}`}
              titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
            />
            <Main>
              <Header />
              <Switch>
                <DefaultRoute path="/" exact component={Home} />
                <DefaultRoute path="/how-it-works" component={HowItWorksContainer} />
                <DefaultRoute path="/contact-us" component={ContactUsContainer} />
                <DefaultRoute path="/privacy-policy" component={PrivacyPolicyContainer} />
                <DefaultRoute path="/terms-conditions" component={TermsConditionsContainer} />
                <DefaultRoute path="/user-agreement" component={UserAgreementContainer} />
                <DefaultRoute path="/down-newsletter" component={DownNewsletter} />
                <DefaultRoute path="/faq" component={FaqContainer} />
                <Route component={NotFound} />
              </Switch>
            </Main>
            <GlobalStyles />
          </AppWrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    startupApp: () => dispatch(startup()),
  };
}

export default hot(connect(mapStateToProps, mapDispatchToProps)(App));
