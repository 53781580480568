import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import { Phone1 } from 'assets/media/images';
import { features } from 'constants/index.js';

const FeatureSection = ({ title, description }) => (
  <Row className="py-3">
    <Col xs={12} sm={12} md={12} lg={12}>
      <h2 className="pb-1" style={{ color: Colors.yellowExtra, textAlign: 'left' }}>
        {title}
      </h2>
      <div>
        {description.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'flex-start' }}>
            <span style={{ marginRight: '10px', marginTop: '5px', color: Colors.white }}>•</span>
            <h5 style={{ textAlign: 'left' }}>{item}</h5>
          </div>
        ))}
      </div>
    </Col>
  </Row>
);

FeatureSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const FeaturesComponent = () => (
  <Container fluid className="py-5" style={{ backgroundColor: Colors.blackBackground }}>
    <Container>
      <h1 className="text-center py-5" style={{ color: Colors.blueButton }}>
        Features
      </h1>
      <Row className="mb-5">
        <Col xs={12} sm={12} md={4} lg={4}>
          {features.slice(0, 2).map((feature, index) => (
            <FeatureSection key={index} title={feature.title} description={feature.description} />
          ))}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="d-flex justify-content-center align-items-center"
        >
          <Container>
            <Row className="d-flex justify-content-center align-items-center ml-2">
              <Image src={Phone1} width="100%" />
            </Row>
          </Container>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4}>
          {features.slice(2).map((feature, index) => (
            <FeatureSection key={index} title={feature.title} description={feature.description} />
          ))}
        </Col>
      </Row>
    </Container>
  </Container>
);

export default FeaturesComponent;
