import React, { Component } from 'react';

export default class UserAgreementContent extends Component {
  render() {
    return (
      <h5 className="titleh5">
        Attention Users:
        <br />
        <br />
        Yieldbucks, User Agreements, Terms and Conditions, Privacy Policy and ALL SITE Policies and
        terms are updated frequently. Though we notify,please check here to view them and keep
        updated.
        <br />
        <br />
        Scope of Agreement:
        <br />
        You Agree by Registering/signup/Register/Join on Yieldbucks LLC or by using our website ,
        membership services,freelance market place, or any content or information or Services (
        Collectively Provided on our site) you/user/users/company( registered/join/Signup as Company
        or Corporation ) , you are bound by our Yieldbucks legal agreement irrespective of the
        users/user/company location which is hereby incorporated by reference (collectively referred
        to as the “Agreement”), whether as a registered member on Yieldbucks, as applicable
        (“Member”), or unregistered user (“Visitor”). If you are using Yieldbucks on behalf of a
        company or other legal entity, you are nevertheless individually bound by this Agreement
        even if your company has a separate agreement with us. If you do not agree with this
        Agreement, do NOT click “Register/Register to Yieldbucks/signup/upgrade/Join” and do not
        access, view, download or otherwise use any Yieldbucks webpage, content, information or
        services. By clicking “Join ”, “Register”, “Sign Up” or similar, or by using any Service,
        you acknowledge that you have read and understood the terms and conditions of this Agreement
        and that you agree to be bound by all of its provisions. Please note that the Yieldbucks
        User Agreement and Privacy Policy are also collectively referred to as Yieldbucks “Terms of
        Service, Site Rules, User Agreement.”
        <br />
        <br />A User/client(Here in after user/affiliate/customer/member) should maintain and
        provide all real/original details and no fictions names are allowed,should
        behave/access/post/content with in the site terms, user agreement, payment terms,policies(
        ALL SITE POLICIES).
        <br />
        <br />
        To be eligible to use the Services, you must meet the following criteria and represent and
        warrant that you:
        <br />
        (1) are the “Minimum Age” (defined below) or older;
        <br />
        (2) are not currently restricted from the Services, or not otherwise prohibited from having
        a Yieldbucks account,
        <br />
        (3) are not a competitor of Yieldbucks or are not using the Services for reasons that are in
        competition with Yieldbucks;
        <br />
        (4) will only maintain one Yieldbucks at any given time;
        <br />
        (5) will use your original name and only provide accurate information to Yieldbucks
        <br />
        (6) have full power and authority to enter into this Agreement and doing so will not violate
        any other agreement to which you are a party;
        <br />
        (7) will not violate any rights of Yieldbucks or third party, including intellectual
        property rights such as copyright or trademark rights; and
        <br />
        (8) agree to provide at your cost all equipment, software, mobile access, and internet
        access necessary to use the Services;
        <br />
        <br />
        <br />
        “Minimum Age” means (a) 18 years old for the People’s Republic of China, (b) 16 years old
        for the Netherlands, (c) 14 years old for the United States, Canada, Germany, Spain,
        Australia and South Korea, and (d) 13 years old for all other countries. However, if
        applicable law requires that you must be older than such ages in order for Yieldbucks to
        lawfully provide the Services to you (including the collection, storage and use of your
        information in accordance with our privacy policy) then the Minimum Age would be such older
        age. The Services are not designed nor intended for use by children or anyone else under the
        age of 13.
        <br />
        <br />
        External Links, 3rd party content,sites and Website Links Policies:
        <br />
        <br />
        <br />
        <br />
        User Disclosure Agreement:
        <br />
        <br />
        You acknowledge, consent and agree that we may access, preserve, and disclose your
        registration and any other information you provide in accordance with the terms of the
        Privacy Policy if required to do so by law or in a good faith belief that such access
        preservation or disclosure is reasonably necessary in our opinion to:
        <br />
        (1) comply with legal process, including, but not limited to, civil and criminal subpoenas,
        court orders or other compulsory disclosures;
        <br />
        (2) enforce this Agreement;
        <br />
        (3) respond to claims of a violation of the rights of third parties, whether or not the
        third party is a Member, individual, or government agency;
        <br />
        (4) respond to customer service inquiries; or
        <br />
        (5) protect the rights, property, or personal safety of Yieldbucks, our Members or the
        public.
        <br />
        Disclosures of users/user/registered user information to third parties other than those
        required to provide customer support, administer this agreement, or comply with legal
        requirements.
      </h5>
    );
  }
}
