import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Modal } from 'react-bootstrap';
import { push } from 'modules/history';
import { Colors } from 'modules/theme';
import CookieConsent from 'react-cookie-consent';

import { ImgBack } from 'assets/media/images';

import HomeSection1 from 'components/HomeSection1';

import {
  FinanceComponent,
  FeaturesComponent,
  BudgetComponent,
  TestimonialsComponent,
  DownloadComponent,
  Footer,
} from 'components';
import UserAgreementContent from '../UserAgreement/UserAgreementContent';

export default class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { location } = this.props;

    if (location && location.query) {
      const { showAgreement } = location.query;
      if (showAgreement) {
        this.setState({ isVisible: true });
      }
    }
  }

  showModal = () => {
    this.setState(prevState => ({ isVisible: !prevState.isVisible }));
  };

  render() {
    const { isVisible } = this.state;

    return (
      <Container
        fluid
        className="p-0"
        style={{ background: `url(${ImgBack}) no-repeat center center`, backgroundSize: 'cover' }}
      >
        {/* Section 1*/}
        <HomeSection1 />

        {/* Section 2*/}
        <FinanceComponent />

        {/* Section 3*/}
        <FeaturesComponent />

        {/* Section 4 */}
        <BudgetComponent />

        {/* Section 5*/}
        <TestimonialsComponent />

        {/* Section 6 */}
        <DownloadComponent />

        {/* Footer */}
        <Footer />

        {/* Cookie*/}
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{
            background: Colors.greyDark,
            paddingBottom: 10,
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: 10,
          }}
          buttonStyle={{
            background: Colors.blueButton,
            color: Colors.white,
            fontSize: '20px',
            width: '164px',
            height: '40px',
            borderRadius: '8px',
          }}
          expires={150}
          onAccept={() => this.showModal()}
        >
          <Container>
            <h4 style={{ color: Colors.yellowExtra }}>Cookie Banner:</h4>
            <span style={{ fontSize: '15px' }}>
              We use cookies to help us understand how you use our website so that we can make
              improvements. Remember that you can always edit cookie settings by accessing your
              website browser.
              <span
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => push('/terms-conditions')}
              >
                {' '}
                Learn more here
              </span>
            </span>
          </Container>
        </CookieConsent>

        {/* Modal */}
        <Modal
          size="lg"
          centered
          show={isVisible}
          onHide={() => this.showModal()}
          dialogClassName="modal-90w"
          aria-labelledby="user-agreement-modal"
          scrollable
          style={{ borderRadius: 5 }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h4>User Agreement</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UserAgreementContent />
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

HomeContainer.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      showAgreement: PropTypes.bool,
    }),
  }),
};
