import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Footer from 'components/Footer';
import { Colors } from 'modules/theme';
import UserAgreementContent from './UserAgreementContent';

export default class TermsConditionsContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container className="mt-5 pb-5">
          <Row className="mt-5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Container fluid className="mt-5">
                <Row className="mt-5">
                  <h1 className="titleH1" style={{ color: Colors.blueButton, fontSize: '30px' }}>
                    User Agreement
                  </h1>
                </Row>
                <Row className="mt-5">
                  <UserAgreementContent />
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
