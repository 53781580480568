import React from 'react';
import styled from 'styled-components';
import logo from 'assets/media/images/Logo.png';

export const Image = styled.img`
  width: ${props => (props.small ? '240px' : '180px')};
  heigth: ${props => (props.small ? '240px' : '80px')};
`;

const Logo = props => <Image className="pl-4" {...props} src={logo} />;

export default Logo;
