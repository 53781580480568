import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { appColor, Colors, headerHeight } from '../modules/theme';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700');

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    height: 100%;
  }

  body {
    font-family: Lato, sans-serif;
    font-size: 16px; /* stylelint-disable unit-blacklist */
    margin: 0;

    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  img {
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${appColor};
    text-decoration: none;

    &.disabled {
      pointer-events: none;
    }
  }

  button {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    line-height: 1;
    padding: 0;
  }

  .navbar-collapse {
    margin-left: 100px;
  }

  #menu-navbar-nav .nav-link {
    color: #000000 !important;
    font-size: 1.5rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  #menu-navbar-nav {
    background-color: ${Colors.white};
    margin-left: 0px !important;
  
  }

  #menu-navbar-nav > div > .dropdown-toggle {
    padding-left: 0rem !important;
  }

  .forms-control {
    margin-top: 10px;
    height: 38px !important;
    font-size: 14px !important;
    background: #F8FBFF !important;
    border: none !important;
    color: #425268 !important;
  }

  .textarea{
    display: block !important;
    width: 100% !important;
    height: 120px !important; 
    padding: .375rem .75rem !important;
    color: #425268 !important;
    font-family: Open Sans, FontAwesome, 'Material Icons' !important;
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    outline-color: #AAD7FF !important;    
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out  !important;    
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .is-invalid-select {
    background-image: none !important;
  }

  .icon-button {
    padding-right: 3px;
  }

  .button-inline {
    margin-right: 5px;
  }

  .text-button {
    text-align: center;
    margin-bottom: auto  !important;
    font-size: 18px  !important;
    font-weight: bold  !important;
    line-height: 25px  !important;
  }

  .text-tabs {
    text-align: center;
    margin-bottom: auto  !important;
    font-size: 14px  !important;
    font-weight: bold  !important;
    line-height: 25px  !important;
  }


  .text-title {
    font-style: normal  !important;
    font-weight: 600  !important;
    font-size: 24px  !important;
    line-height: 33px  !important;
  }



  .text-header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  .text-footer {
    font-size: 13px !important;
    text-align: center;
    color: ${Colors.yellowExtra} !important;
  }
  
  .pointer {
    cursor: pointer !important;
  }

  .button-rounded {
    border-radius: 8px !important;
    width: 20px;
    height: 20px;
    padding: 5px;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-text{
    font-style: normal;
    font-weight: normal;
    color: #425268;
    font-size: 14px !important;
    line-height: 19px;
  }

  .input-select{
    border: none !important;
    color: #0084EA  !important;
    font-weight: bold  !important;
  }

  .text-error {
    font-size: 12px; 
    margin-top: 5px;
    color: red;
  }

  .form-control-placeholdericon {
    font-family: Open Sans, FontAwesome, 'Material Icons';
  }

  .form-control{
    height: calc(2em + 0.75rem + 4px) !important;
    font-size: 1.5rem !important;
  }

  .border-none {
    border: none !important;
  }

  .form-check {
    margin: 5px 0px;
  }

  .form-check-label {
    padding-right: 5px;
    padding-top: 1px;
    font-size: 14px;

  }
  .checkbox-filled {
    border-color: #92aad0;
    background-color: #92aad0;
  }

  tr {
    border-width: 1px;
    border-color: ${Colors.gray2};
    border-style: solid;
    border-radius: 40000px !important;
  }

  .invalid-feedback{
    font-size: 100% !important;
  }

  .button{
    font-size: 18px !important;
    border-radius: 8px !important;
  }

  .headerWrapper { 
    background-color: ${Colors.greyDark};
    height: ${headerHeight}px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    padding-top: 10px;
    box-shadow: 0 -2px 10px rgba(72, 134, 255, 0.15);

  }

  .appBackground {
    background-color: ${Colors.greyDark};
  }

  .cardHome {
    align-content: center;
    background-color: ${Colors.blueDark};
    min-height: 200px;
    border-radius: 16px;
  }

  .inputBox  {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .replaceImageText {
    color: white;
    cursor: pointer;
    position: absolute;
    top: 40%;
    background-color: rgba(178, 178, 178, 0.4);
    border-radius: 6px;
    padding: 5px;
    left: 33%;
  }

  .imageHolder {
    width: 150px;
    height: 150px;
    position: relative;
    overflow: hidden;
    border-radius: 100px;
  }

  .background-activated span {
    background-color: #B1D8B8 !important;
  }

  .background-deactivated span {
    background-color: #FFE3B8 !important;
  }

  .imageUser {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: cover;
  }

  .pageWithMenu {
    display: flex;
  }
  .navItem {
    font-style: normal;
    font-weight: bold;
    line-height: 10px;
    color: ${Colors.white};
  }
  .navButton {
    width: 164px;
    height: 40px;
    border-radius: 8px !important;
  }

  .navButtonText {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 33px;
    color: #FFFFFF;
  }

  h1 {
    font-family: Open Sans !important;
    font-style: normal  !important;;
    font-weight: bold  !important;;
    font-size: 45px  !important;;
    line-height: 50px  !important;;
    color: ${Colors.white};
  }
  
  h2 {
    font-family: Open Sans !important;;
    text-align: center;
    font-style: normal  !important;;
    font-weight: bold  !important;;
    font-size: 26px  !important;;
    color: ${Colors.white};
  }

  h4 {
    font-family: Open Sans  !important;;
    font-style: normal  !important;;
    font-weight: bold  !important;;
    font-size: 20px  !important;;
    color:  ${Colors.white};
  }


  .slanted {
    background-color: #007AFF;
    padding-top: 50px;
    padding-bottom: 150px;
    -webkit-clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);
    clip-path: polygon(0 6%, 100% 0, 100% 94%, 0 100%);

    @media (max-width: 700px) {
      clip-path: polygon(0 2%, 100% 0, 100% 98%, 0 100%);
    }
  }

  h5 {
    font-family: Open Sans  !important;;
    text-align: justify;
    font-style: normal  !important;;
    font-weight: normal  !important;;
    font-size: 18px  !important;;
    line-height: 25px  !important;;
    color: ${Colors.white};
  }
  .menu {
    width: 250px;
    z-index: 100;
    @media (max-width: 700px) {
      width: 60px;
    }
  }

  .pageContent {
    width: 100%;
    background-color: ${Colors.white};
    overflow: auto;
    z-index: 100;
    position: fixed;
    height: calc(100%);
   
  }

  .h4-dropdown{ 
    color: ${Colors.blue2};
    font-weight: bold;
  }

  .text-current-plan {
    color: ${Colors.blue};
    font-weight: bold;
  }

  .navDropdownBox{
    width: 250px; 
    background: #F8FBFF; 
  }

  .menu-items {
    width: 95% !important;
    margin-top: 30px !important;
    padding-top: 50px !important;
    height: 150px !important;
    border: 2px solid ${Colors.blueLight}!important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }

  .form-check-label {
    padding-left: 13px;
    padding-top: 1px;
    font-size: 15px;
  } 

  .label-check {
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 20px !important;
    line-height: 27px !important;
  }

  .form-check-input {
    position: absolute;
    margin-top: 10px !important;
  }

  .menu-items-active {
    width: 95% !important;
    margin-top: 30px !important;
    padding-top: 50px !important;
    height: 150px !important;
    cursor: pointer !important;
    background: ${Colors.blueLight1};
  }

  .select-jobs {
    height: 30px !important;
    width: 110px !important;
    color: #fff !important;
    border-radius: 3px !important;
    border: none !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }

  .select-jobs-newticket {
    background: #56D053 !important;
  }
  
  .select-jobs-research {
    background: #008080 !important;
  } 
  
  .select-jobs-submitted  {
    background: #3D2AB9 !important;
  }

  .select-jobs-quote  {
    background: #E445B4 !important;
  }

  .select-jobs-onhold  {
    background: #800080 !important;
  }
  
  .select-jobs-na  {
    background: #8BCDEF !important;
  }
  
  .select-jobs-voidcancel  {
    background: #808000 !important;
  }
  
  .select-jobs-voidduplicate  {
    background: #DC4B4B !important;
  }
  
  .select-jobs-voidlost  {
    background: #808080 !important;
  }
  
  .select-jobs-testticket  {
    background: #EB6A36 !important;
  }

  .width-70{
    max-width:70% !important;
  }
  .width-100{
    max-width:100% !important;
  }

  .text-aling-center{
    text-align: center !important;
  }

  .textWhite{
    color: ${Colors.white};
  }

  .textYellowExtra{
    color: ${Colors.yellowExtra};
  }

  .modal-content {
    background-color: ${Colors.greyDark} !important;

  }
`;

export default () => <GlobalStyle />;
