import { all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import client from '../modules/client';
import { startupApp } from './startup';

/**
 * rootSaga
 */
const api = client.create();

export default function* root() {
  yield all([
    // startup
    takeLatest(ActionTypes.STARTUP, startupApp, api),
  ]);
}
