import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { push } from 'modules/history';
import Secured from '../../assets/media/images/Secured.png';
import Plaid from '../../assets/media/images/Plaid.png';
import TwoFactor from '../../assets/media/images/TwoFactor.png';
import DataSecurity from '../../assets/media/images/DataSecurity.png';

const Security = () => (
  <Container>
    <Row className="d-flex justify-content-center align-items-center mt-5">
      <Col xs={12} sm={12} md={10} lg={10}>
        <Row className="d-flex justify-content-center align-items-center pb-2">
          <Image src={Secured} width={60} className="pb-3" />
          <h2 className="pb-3">Platform and Data Security</h2>
        </Row>

        <h5 className="text-center">
          Concerned about putting your financial information on Yieldbucks? Don’t worry! We use
          Plaid financial services, so we do not store user financial data. Learn more by visiting
          our{' '}
          <span style={{ cursor: 'pointer' }} onClick={() => push('/privacy-policy')}>
            Privacy Policy.
          </span>
        </h5>
        <div className="d-none d-md-flex justify-content-around align-items-end pb-5 mt-5">
          <Image src={DataSecurity} width={100} />
          <Image src={Plaid} width={100} />
          <Image src={TwoFactor} width={100} />
        </div>
      </Col>
    </Row>

    <Row className="d-flex d-md-none justify-content-center align-items-center py-5">
      <Col xs={4} sm={4}>
        <Image src={DataSecurity} width="100%" />
      </Col>
      <Col xs={4} sm={4}>
        <Image src={Plaid} width="100%" />
      </Col>
      <Col xs={4} sm={4}>
        <Image src={TwoFactor} width="100%" />
      </Col>
    </Row>
  </Container>
);

export default Security;
