import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import { Phone2 } from 'assets/media/images';

const BudgetComponent = () => (
  <Container className="my-5 py-5">
    <Row className="d-flex justify-content-center align-items-center">
      <Col
        xs={12}
        sm={12}
        md={4}
        lg={4}
        className="d-flex justify-content-center align-items-center"
      >
        <Container>
          <h2 style={{ color: Colors.yellowExtra, textAlign: 'left' }}>Every Penny Saved</h2>
          <h2 style={{ color: Colors.yellowExtra, textAlign: 'left' }}>Goes a Long Way</h2>

          <Row className="p-4">
            <h5>
              With Yieldbucks, earning cashback and rewards has never been easier. Let us worry
              about which credit card to use while shopping for:
              <ul className="pt-4">
                <li>Groceries</li>
                <li>Clothes</li>
                <li>Gas</li>
                <li>Entertainment</li>
              </ul>
            </h5>
          </Row>
        </Container>
      </Col>
      <Col
        xs={12}
        sm={6}
        md={6}
        lg={6}
        className="d-flex justify-content-center align-items-center"
      >
        <Image src={Phone2} width="60%" />
      </Col>
    </Row>
  </Container>
);

export default BudgetComponent;
