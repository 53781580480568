import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import {
  QRGooglePlay,
  QRAppStore,
  AppStore,
  GooglePlay,
  TwoPhones,
  Ellipse,
} from 'assets/media/images';

import Security from 'components/Security';

const HomeSection1 = () => (
  <Container
    fluid
    className="mt-5"
    style={{
      background: `url(${Ellipse}) no-repeat center center`,
      backgroundColor: Colors.blackBackground,
    }}
  >
    <Container>
      <Row>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="d-flex justify-content-center align-items-center mb-5"
        >
          <Container fluid className="mt-5">
            <Row>
              <h1 style={{ color: Colors.blueButton }}>Follow Cashbacks,</h1>
              <h1 style={{ color: Colors.yellowExtra }}>Get Rewards</h1>
            </Row>
            <Row className="mt-5">
              <h5>
                With Yieldbucks, never miss out on getting the best benefits on your purchases. Our
                app tells you the best credit cards to use to maximize your cashback and rewards
              </h5>
            </Row>
            <div className="d-none d-md-block">
              <Row className="mt-4 pt-4">
                <Col style={{ textAlign: 'center' }}>
                  <Image src={QRGooglePlay} width={198} className="pb-4" />
                  <a
                    href="https://play.google.com/store/apps/details?id=com.yieldbucks"
                    target="_blank"
                  >
                    <Image src={GooglePlay} />
                  </a>
                </Col>
                <Col style={{ textAlign: 'center' }}>
                  <Image src={QRAppStore} width={198} className="pb-4" />
                  <a
                    href="https://apps.apple.com/ar/app/yieldbucks/id1569402555?l=en"
                    target="_blank"
                  >
                    <Image src={AppStore} />
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="d-flex justify-content-center align-items-center"
        >
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Image src={TwoPhones} width="80%" />
            </Row>
            <Row className="mt-4 pt-4 d-md-none">
              <Col style={{ textAlign: 'center' }}>
                <Image src={QRAppStore} width={198} className="pb-4" />
                <a
                  href="https://apps.apple.com/ar/app/yieldbucks/id1569402555?l=en"
                  target="_blank"
                >
                  <Image src={AppStore} />
                </a>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <Image src={QRGooglePlay} width={198} className="pb-4" />
                <a
                  href="https://play.google.com/store/apps/details?id=com.yieldbucks"
                  target="_blank"
                >
                  <Image src={GooglePlay} />
                </a>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Security />
    </Container>
  </Container>
);

export default HomeSection1;
