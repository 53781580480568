import React, { Component } from 'react';
import './styles.css';
import { Col, Button, Container } from 'react-bootstrap';
import { push } from 'modules/history';
import { NavHashLink } from 'react-router-hash-link';

export default class DropdownNav extends Component {
  render() {
    return (
      <Container fluid className="d-none d-md-flex d-lg-flex appBackground">
        <Col className="pt-2">
          <button
            type="button"
            className="navItem pointer textYellowExtra"
            onClick={() => push('/how-it-works')}
            style={{ background: 'none', border: 'none', padding: 0 }}
          >
            <h3>How it Works</h3>
          </button>
        </Col>
        <Col className="pt-2">
          <button
            type="button"
            className="navItem pointer textYellowExtra"
            onClick={() => push('/contact-us')}
            style={{ background: 'none', border: 'none', padding: 0 }}
          >
            <h3>Contact us</h3>
          </button>
        </Col>
        <Col>
          <NavHashLink to="/#download-app">
            <Button className="navButton">
              <p className="navButtonText">Download</p>
            </Button>
          </NavHashLink>
        </Col>
      </Container>
    );
  }
}
