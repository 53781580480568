import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import { QRGooglePlay, QRAppStore, GooglePlay, AppStore, Cards } from 'assets/media/images';

const DownloadComponent = () => (
  <Container fluid>
    <Row className="d-flex justify-content-center align-items-center mt-5 py-5 mb-5">
      <Col xs={12} sm={12} md={6} lg={6} className="p-0">
        <Image src={Cards} />
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <Container>
          <h1 style={{ color: Colors.blueButton, textAlign: 'center' }}>Follow Cashbacks,</h1>
          <h1 style={{ color: Colors.yellowExtra, textAlign: 'center' }}>Get Rewards</h1>
          <Container>
            <Row>
              <Container>
                <h5 className="text-center px-5">
                  Download Yieldbucks today and always use the best credit cards!
                </h5>
              </Container>
            </Row>
            <Row className="pt-4 px-5">
              <Col style={{ textAlign: 'center' }}>
                <Image src={QRAppStore} width={198} />
                <a
                  href="https://apps.apple.com/ar/app/yieldbucks/id1569402555?l=en"
                  target="_blank"
                >
                  <Image src={AppStore} width={248} />
                </a>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <Image src={QRGooglePlay} width={198} />
                <a
                  href="https://play.google.com/store/apps/details?id=com.yieldbucks"
                  target="_blank"
                >
                  <Image src={GooglePlay} />
                </a>
              </Col>
            </Row>
          </Container>
        </Container>
      </Col>
    </Row>
  </Container>
);

export default DownloadComponent;
