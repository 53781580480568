import React, { useState } from 'react';
import { Container, Row, Col, Image, Carousel } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import { User2, QuoteLeft, QuoteRight } from 'assets/media/images';

const testimonials = [
  {
    name: 'Matt Cannon',
    title: 'Head of Marketing',
    text:
      "I love this App, I just put my cards in and it tells me where there are offers. It's awesome! Thanks for making this so awesome.",
    image: User2,
  },
  {
    name: 'Anna Moore',
    title: 'Web Designer',
    text:
      'This platform has transformed my workflow. The user experience is seamless, and I can easily manage my projects. Highly recommend it!',
    image: User2,
  },
  {
    name: 'John Carter',
    title: 'Lead Developer',
    text:
      'The integration capabilities are outstanding. Our team productivity has increased manifold since we started using this tool.',
    image: User2,
  },
];

const TestimonialsComponent = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  return (
    <Container fluid style={{ backgroundColor: Colors.greyDark }}>
      <Container className="py-5">
        <Row className="mb-4">
          <Col className="d-flex justify-content-center align-items-center">
            <h2 className="pt-3" style={{ color: Colors.yellowExtra }}>
              What our clients say
            </h2>
          </Col>
        </Row>
        <Row
          style={{
            minHeight: 200,
            justifyContent: 'space-around',
          }}
        >
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="d-flex justify-content-start align-items-start pb-4"
          >
            <Image src={QuoteLeft} width="80" height="80" />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={5}
            lg={5}
            className="d-flex justify-content-center align-items-center px-2"
          >
            <h5 className="text-center textWhite">{testimonials[currentTestimonial].text}</h5>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={2}
            lg={2}
            className="d-flex justify-content-end align-items-end px-2 pb-4"
          >
            <Image src={QuoteRight} width="80" height="80" />
          </Col>
        </Row>

        <Row
          className={`d-none d-md-flex ${
            currentTestimonial === 0
              ? 'justify-content-start'
              : currentTestimonial === 1
              ? 'justify-content-center'
              : 'justify-content-end'
          } align-items-center mx-5 px-5`}
          style={{ borderBottom: 'solid 2px #60606D' }}
        >
          {testimonials.map((t, i) => (
            <div
              key={i}
              style={{
                width: i === currentTestimonial ? '25%' : '0',
                borderBottom: i === currentTestimonial ? 'solid 2px white' : 'none',
                transition: 'width 0.5s',
              }}
            />
          ))}
        </Row>

        <Row className="d-none d-md-flex mt-4">
          {testimonials.map((t, i) => (
            <Col
              key={i}
              xs={12}
              md={4}
              className={`d-flex justify-content-center align-items-center text-center p-2 pointer ${
                i === currentTestimonial ? 'active' : ''
              }`}
              onClick={() => setCurrentTestimonial(i)}
            >
              <div
                className="d-flex w-100 align-items-center py-4"
                style={{
                  backgroundColor: Colors.blackBackground,
                  justifyContent: 'center',
                  borderRadius: 8,
                  cursor: 'pointer',
                }}
              >
                <div className="mx-3">
                  <h5 style={{ marginBottom: 0 }}>{t.name}</h5>
                  <h5 style={{ color: Colors.yellowExtra }}>{t.title}</h5>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <div className="d-block d-md-none">
          <Row
            className="d-flex justify-content-center align-items-center mx-5 px-5"
            style={{ borderBottom: 'solid 2px #60606D' }}
          >
            <div
              style={{
                width: '25%',
                borderBottom: 'solid 2px white',
              }}
            />
          </Row>
          <Carousel
            indicators={false}
            activeIndex={currentTestimonial}
            controls={false}
            onSelect={selectedIndex => setCurrentTestimonial(selectedIndex)}
          >
            {testimonials.map((testimonial, index) => (
              <Carousel.Item
                key={index}
                className="my-4"
                style={{
                  backgroundColor: Colors.blackBackground,
                  borderRadius: 8,
                }}
              >
                <Row className="d-flex justify-content-center align-items-center text-center p-2">
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-items-center text-center p-2"
                  >
                    <div className="mx-3">
                      <h5 style={{ marginBottom: 0 }}>{testimonial.name}</h5>
                      <h5 style={{ color: Colors.yellowExtra }}>{testimonial.title}</h5>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    </Container>
  );
};

export default TestimonialsComponent;
