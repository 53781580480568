import React from 'react';
import { Navbar, Col, Container, Image } from 'react-bootstrap';
import { Logo } from 'components/';
import { Vector } from 'assets/media/images';
import { push } from '../modules/history';
import MenuItemMobile from './MenuItemMobile';
import MenuExpand from './MenuExpand';

export default class Header extends React.PureComponent {
  render() {
    return (
      <div className="headerWrapper">
        <Navbar collapseOnSelect expand="lg" className="p-0">
          <Container className="p-0">
            <Container fluid className="p-0">
              <Col xs={10} sm={4} md={3} lg={5} className="p-0 pointer">
                <Logo onClick={() => push('/')} />
              </Col>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <Image src={Vector} width={22} />
              </Navbar.Toggle>
              <Navbar.Collapse id="menu-navbar-nav">
                <MenuExpand />
                <MenuItemMobile />
              </Navbar.Collapse>
            </Container>
          </Container>
        </Navbar>
      </div>
    );
  }
}
