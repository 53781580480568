// @flow
/**
 * Client
 * @module Client
 */
import apisauce from 'apisauce';
import config from '../config';

const create = (baseURL = config.BASE_URL) => {
  const api = apisauce.create({
    baseURL,
  });
  // api
  const setAuthorization = token => api.setHeader('jwt', token);
  const removeAuthToken = () => api.deleteHeader('Authorization');

  return {
    // api
    setAuthorization,
    removeAuthToken,
  };
};

export default {
  create,
};
