import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Colors } from 'modules/theme';
import Footer from '../../components/Footer';

export default class FaqContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Container className="mt-5 pb-5">
          <Row className="mt-5">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Container fluid className="mt-5">
                <Row className="mt-5">
                  <h1 className="titleH1" style={{ color: Colors.blueButton, fontSize: '30px' }}>
                    FAQ’s
                  </h1>
                </Row>
                <Row>
                  <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                    Here are frequently asked questions, please email us if you do not find what you
                    are looking for.
                  </h5>
                </Row>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>1) Does Yieldbucks store and sell my data.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Yieldbucks does not store financial data of linked cards, we only get the Card
                  name in order to match our library and show rewards. We store registration
                  information which is secured by google secured database platform. Please refer to
                  our privacy policy for details on how we use and store data.
                </h5>

                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    2) How to reset/change password and maintain securely.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Please click on forgot password link from the Login page and follow instructions
                  on screen. If you have trouble resetting password, then reach out to
                  <a href="mailto:support@yieldbucks.com" target="_blank">
                    {' support@yieldbucks.com'}
                  </a>
                  . You can also change password from the profile menu once logged in and we
                  recommend changing password every 30 days following strict password rules.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    3) Why my account is blocked/disabled? Is your account blocked.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Due to security reasons whenever we notice suspicious activity, account will be
                  blocked in order to protect your account. When this happens, please reach out to
                  <a href="mailto:support@yieldbucks.com" target="_blank">
                    {' support@yieldbucks.com '}
                  </a>{' '}
                  for unblocking your account and immediately change your password and security
                  questions.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>4) How do I update my personal information.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  If you wish to change personal information such as registered phone or address,
                  please login and update from the profile section. To better serve and have best
                  experience, we recommend updating your profile with accurate address with zip
                  code. We will use this info to verify your account if required in case you cannot
                  sign in.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>5) Can i change my email after registration.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  It is not possible to change email once registered, please create a new account
                  with new email address and have all information completed.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    6) How can users protect information and help us.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  More often social media accounts are subjected to data & security breaches, and it
                  is common for people to use same credentials to log into multiple
                  accounts/services, this is a serious security issue and password used must be
                  different and must be changed frequently.
                  <ul>
                    <li>Avoid using same password multiple times.</li>
                    <li>
                      make sure password is min 8 char in length and includes special characters and
                      does not include your personal details such as names.
                    </li>
                    <li>setup two-factor authentication.</li>
                  </ul>
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    7) How to enable or disable two-factor authentication.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Login to your account and go to security settings, look for the option to enable
                  two-factor authentication and turn-on after passcode verification. We recommend
                  strongly to enable 2-factor authentication which will be an added extra security
                  for linking and unlinking your cards.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    8) Lost your phone or stolen? how to keep your account safe.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Reach out to{' '}
                  <a href="mailto:support@yieldbucks.com" target="_blank">
                    {' support@yieldbucks.com '}
                  </a>{' '}
                  and request account block. We will unblock when we receive confirmation from you.
                  Once unblocked please change your password immediately.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>9) How do i delete my account and data.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  You can easily delete your account from the profile menu or reach out to
                  <a href="mailto:support@yieldbucks.com" target="_blank">
                    {' support@yieldbucks.com '}
                  </a>{' '}
                  to delete your account. Once account is deleted all data pertaining to your
                  account will be deleted within 72 hours, in case you change your mind and opt not
                  to delete you must reach out to support within 72 hours.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>10) How to LINK your card.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  You can link your card during registration process or skip and link at a later
                  point in time. Click on “+” symbol under my cards section and follow the prompts
                  from Plaid. We use plaid to seamlessly connect your card to Yieldbucks which is
                  secured and does not store your credentials on our platform. Once verification
                  process is completed, your card will be linked successfully. Sync your linked
                  card: Now, you will see the card linked and a toggle button to sync for rewards,
                  hit the toggle button and if linked card matches our library, then your card is
                  synced automatically otherwise you will be prompted with a search screen where you
                  can start searching for respective card and choose from the list and complete the
                  sync process.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>11) Why should I link/synch my cards?</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  In order to identify the right rewards/cashback for your credit card you need link
                  and sync your cards.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    12) How to view cards and manage linked cards.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Linked cards are grouped by institution, hit the pencil icon to see the cards list
                  that are linked and belongs to a institution and then use the toggle ON to sync in
                  order to receive rewards or toggle OFF to disable the card so no rewards will be
                  show for the respective card.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>13) UNLINK a financial institution.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  If you have multiple cards from same institution (bank) and linked, UNLINKING the
                  bank will remove all the cards belong to bank. Use the icon to UNLINK and complete
                  2-factor verification (if enabled) and proceed to UNLINK.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    14) How can I search for rewards for a particular card or store.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  From the home screen, you can search for rewards by simply typing in category such
                  as food, gas, Indian, Italian etc…or by business such as Target, Walmart, Amazon,
                  Kohls etc….if there are rewards matching cards then results will be shown.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    15) Where I can find all rewards for my linked cards.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Under My cashback screen, you can find rewards for all cards linked and can also
                  filter by categories.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>16) How to setup budget alerts.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Under Budget alerts screen, click on “+” for the selected linked card from
                  dropdown and then choose category for the alert and set a target amount, when
                  budget is reached you will receive a notification.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    17) How often do I get notified for Minimum Due Payments.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  {
                    'For the linked cards, you will receive minimum due notifications 3 days & 1 day before the due date automatically when enabled under Settings > Minimum Payment.'
                  }
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    18) Can I check my financial details of all linked cards.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  You can go to financial health screen , you will see complete summary of credit
                  limits across all linked cards, Due dates , Limits and can also filter by
                  respective cards.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>19) How to check Monthly spend.</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Under Financial Health screen, you will see monthly spend for each category per
                  card and setup budget alerts to better manage spending patterns and keep track of
                  finances and set goals.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    20) Can I search or find a reward if I don’t own the card or LINK my card.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  {
                    'Absolutely, you do not have to link in order to receive rewards, you can go to My Cashback screen > Recommended section and search for rewards by category, which will show the cards offering rewards and also APPLY for the card if you do not own it. You can also click on “Find a New Card” and search for a particular card/bank in order to see available rewards and click on it to “LINK” if you already own it  or “APPLY” if you do not own it.'
                  }
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>21) Does the app drain my device battery?</h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  No, Yieldbucks app does not drain device battery, you can Android and Apple device
                  software to monitor battery usage and devices will show if app consumes more
                  battery and put it to sleep.
                </h5>
                <Row className="mt-5">
                  <h2 className="text-float-left" style={{ fontSize: '30px' }}>
                    22) How to determine when to use ALWAYS location setting and While Using Only
                    option.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  We recommend enabling Location services to ALWAYS before going out for shopping or
                  Dining etc…so that you receive rewards notifications in near real time so that you
                  can use appropriate card for payment to maximize rewards. Set it to “ While using
                  App Only” at all remaining times and perform manual rewards search.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    23) How long will be rewards notifications retained and sent frequently.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Rewards notifications are retained within the app for 30 days. Notifications
                  already sent in near real time for a business or store will not be repeated again
                  for the next 48Hrs. You can always check in the app what rewards are available.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    24) How do I reach support in case of any data issues.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  Please send an email with username and card details to{' '}
                  <a href="mailto:support@yieldbucks.com" target="_blank">
                    {' support@yieldbucks.com'}.
                  </a>
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    25) What if a card I want to add is not available in library.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  After linking the card and trying to sync using the toggle option there is a
                  possibility that we may not have card in our library. In such cases users can
                  manually add their card in order to sync if card is not found in library while
                  searching, we will verify and approve the card before it shows up under my cards
                  and verification process may take 24-48 Hours.
                </h5>
                <Row className="mt-5">
                  <h2 style={{ fontSize: '30px' }}>
                    26) How do I open a new feature request or report a BUG.
                  </h2>
                </Row>
                <h5 style={{ color: Colors.blueLightButtonSecondary }}>
                  {
                    'Under settings > support , you will see the options to report bugs and request new features and open support tickets. Please include as much details as you can in order to better understand the issue. Also, you can send email directly at'
                  }
                  <a href="mailto:support@yieldbucks.com" target="_blank">
                    {' support@yieldbucks.com '}
                  </a>
                </h5>
              </Container>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }
}
